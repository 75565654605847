import { format as formatDateFns, sub, addMinutes } from 'date-fns';
import numeral from 'numeral';
import { v4 as uuidv4 } from 'uuid';
import { get, set } from './persistentStore';

let branches = {};
let clients = {};

export function setClientCache(clientsDetails = []) {
  let branchesTmp;
  if (!(clientsDetails instanceof Array && clientsDetails.length)) return;

  branchesTmp = clientsDetails.flatMap(({ id, name, branches }) =>
    branches.map((branch) => ({ ...branch, clientId: id, clientName: name }))
  );
  branches = branchesTmp.reduce((acc, branch) => {
    acc[branch.id] = branch;
    return acc;
  }, {});
  clients = arrayToObject(clientsDetails);
  set('clients', branches);
}

export function getBranchDetails(branchId) {
  const defaultDetails = { id: branchId, name: 'NotFound', clientName: 'NotFound' };
  const branchDetails =
    branches[branchId] || get('clients') ? get('clients')[branchId] : defaultDetails;

  return branchDetails ? branchDetails : defaultDetails;
}

export function getClientDetails(clientId) {
  return clients[clientId]
    ? clients[clientId]
    : { id: clientId, name: 'NotFound', clientName: 'NotFound' };
}

export function branchSelect(clients) {
  return Object.values(clients).map(({ name, branches }) => ({
    label: name,
    options: branches.map(({ id, name }) => ({ label: name, value: id })),
  }));
}

export function clientSelect(clients) {
  return Object.values(clients).map(({ name, id }) => ({
    label: name,
    value: id,
  }));
}

export function principalSelect(principals) {
  return principals.map(({ name, id }) => ({
    label: name,
    value: id,
  }));
}

export function getCustomDateRegExp() {
  return /[a-z]|[0-9]{1,2}/gi;
}

export function getWorkingDate() {
  if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_DEFAULT_DATE)
    return Date.parse(process.env.REACT_APP_DEFAULT_DATE);

  const curDate = new Date();
  return curDate;
  // return getHours(curDate) < 12 ? curDate : addDays(curDate, isSaturday(curDate) ? 2 : 1);
}

export const remove = (array, value, key) => {
  const index = array.findIndex((obj) => (key ? obj[key] === value : obj === value));
  return removeByIndex(array, index);
};

export const removeByIndex = (array, index) =>
  index >= 0 ? [...array.slice(0, index), ...array.slice(index + 1)] : array;

export const arrayToObject = (arr, id = 'id') =>
  Object.assign({}, ...arr.map((item) => ({ [item[id]]: item })));

export const isVal = (val) => !(val === undefined || val === null);
export const isEmpty = (obj) =>
  obj ? Object.keys(obj).length === 0 && obj.constructor === Object : false;

export const formatVolume = (volume) => numeral(volume / 1000).format('0.00');
export const formatWeight = (weight) => numeral(weight).format('0.00');
export const formatCurrency0 = (value) => `₹${numeral(value).format('0,0')}`;
export const formatCurrency = (value) => `₹${numeral(value).format('0,0[.]00')}`;
export const formatCurrencyNum = (value) => `${numeral(value).format('0,0[.]00')}`;
export const formatCurrencyNum0 = (value) => `${numeral(value).format('0,0')}`;
export const format = (value) => numeral(value).format('0.00');
export const format0 = (value) => Math.round(value);
export const format1 = (value) => numeral(value).format('0.0');
export const format2 = (value) => numeral(value).format('0.00');
export const formatDistance = (dist) => numeral(dist / 1000).format('0.0');
export const formatDate = (date) => formatDateFns(date, 'yyyy-MM-dd');
export const formatDateHumanReadable = (dateStr) => formatDateFns(new Date(dateStr), 'dd MMM yyyy');
export const formatDateTime = (date) => formatDateFns(date, 'd LLL yyyy, h:mm a');
export const formatDateForSession = (date) =>
  `${formatDateFns(date, 'do')} ${formatDateFns(date, 'LLL')}, ${formatDateFns(
    date,
    'eee'
  )}, ${formatDateFns(date, 'hh')}:${formatDateFns(date, 'mm')} ${formatDateFns(date, 'aaa')}`;
export const formatTime = (sec) =>
  isVal(sec)
    ? `${Math.trunc(sec / (60 * 60))}h ${numeral(Math.trunc((sec % (60 * 60)) / 60)).format('00')}m`
    : '--';
export const formatTimeSec = (sec) =>
  isVal(sec)
    ? `${Math.trunc(sec / (60 * 60))}h ${numeral(Math.trunc((sec % (60 * 60)) / 60)).format(
        '00'
      )}m ${Math.trunc(sec % 60)}s`
    : '--';
export const formatTimeSpan = (sec, hoursLimit = 24) => {
  if (!isVal(sec)) return '--';

  const hours = Math.trunc(sec / (60 * 60));
  const minutes = Math.trunc((sec % (60 * 60)) / 60);

  if (hours >= 1) return `${Math.round(hours)}h`;
  if (hours >= hoursLimit) return `${Math.trunc(hours / 24)}d`;

  return `${numeral(minutes).format('00')}m`;
};

export const formatTimeMilitary = (sec) =>
  isVal(sec)
    ? `${numeral(Math.trunc(sec / (60 * 60))).format('00')}:${numeral(
        Math.trunc((sec % (60 * 60)) / 60)
      ).format('00')}`
    : '--';

export const formatDayTime = (date) => formatDateFns(date, 'h:mm a');
export const formatDayTime24 = (date) => formatDateFns(date, 'H:mm');

export const formatTimeMinSec = (sec) =>
  sec ? `${Math.trunc(sec / 60)}:${numeral(Math.trunc(sec % 60)).format('00')}` : '--';

export const debugPropChange = (prevProp, nextProp) => {
  Object.entries(nextProp).forEach(([key, value]) => {
    if (prevProp[key] !== value) {
      console.log(`${key}: ${prevProp[key]} -> ${value}`); // eslint-disable-line
      return false;
    }
  });
  return false;
};
export const formatTo24HourTime = (date) => {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};
const minutesTo24HourTime = (minutes) => {
  const time = addMinutes(new Date(0, 0, 0, 0, 0), minutes);
  return formatDateFns(time, 'HH:mm');
};
export const convertDeliveryWindowTo24HourFormat = (deliveryWindow) =>
  deliveryWindow?.map(({ start, end }) => ({
    start: minutesTo24HourTime(start),
    end: minutesTo24HourTime(end),
  }));
const timeToMinutes = (time) => {
  if (typeof time !== 'string' || !time.includes(':')) {
    return null;
  }

  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};
export const isWithinTrackingTime = (etaStatus, dayStart, dayEnd) =>
  timeToMinutes(etaStatus) >= timeToMinutes(dayStart) &&
  timeToMinutes(etaStatus) <= timeToMinutes(dayEnd)
    ? 'ON-TIME'
    : timeToMinutes(etaStatus) > timeToMinutes(dayEnd)
    ? 'DELAYED'
    : 'BEFORE-TIME';

export const getPropsbyVal = (obj, val) =>
  Object.entries(obj)
    .filter((en) => en[1] === val)
    .map((en) => en[0]);

export const byId = (arr, id, key = 'id') => arr.find((e) => e && e[key] === id);

export const uuid = uuidv4;

const R = 6371e3; // Radius of earth in metres

function convToRad(degrees) {
  return degrees * (Math.PI / 180);
}

export function haversine(lat1, lon1, lat2, lon2) {
  // in degrees
  // convert to radians
  lat1 = convToRad(lat1);
  lon1 = convToRad(lon1);
  lat2 = convToRad(lat2);
  lon2 = convToRad(lon2);

  const dLat = lat2 - lat1;
  const dLon = lon2 - lon1;

  const a =
    Math.pow(Math.sin(dLat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dLon / 2), 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
}

export const isBranch = (branch) => (branch ? (branch.id !== -1 ? true : false) : false);

export function arraysIntersection(arrays) {
  let result = [];
  if (!arrays || !arrays.length) return [];

  for (let i = 0, length = arrays[0].length; i < length; i++) {
    let item = arrays[0][i];
    if (result.includes(item)) continue;
    let j;
    for (j = 1; j < arrays.length; j++) {
      if (!arrays[j].includes(item)) break;
    }
    if (j === arrays.length) result.push(item);
  }
  return result;
}

export function truncate(str, limit = 25) {
  if (str && str.length < limit) return str;
  return '...' + str.substring(str.length - limit);
}

export function getDateRange(currentDate, daysCount) {
  return [formatDate(sub(currentDate, { days: daysCount })), formatDate(currentDate)];
}

export function contains(a = '', b = '') {
  return a.toLowerCase().includes(b.toLowerCase());
}
export function codeNameContains({ code, name } = {}, txt) {
  return contains(code, txt) || contains(name, txt);
}

export function encodeQueryParams(obj = {}) {
  const entries = Object.entries(obj);
  const res = entries
    .filter(([, v]) => !!v)
    .map(([k, v]) => `${k}=${v}`)
    .join('&');

  return res;
}

export const getFileFormData = async (e, key = 'file') => {
  const data = new FormData();
  data.append(key, e.target.files[0]);
  return data;
};

export const max = (data, getVal = (x) => x) => {
  if (!data?.length) return;
  return data.reduce((a, c) => Math.max(a, getVal(c)), -Infinity);
};

export const min = (data, getVal = (x) => x) => {
  if (!data?.length) return;
  return data.reduce((a, c) => Math.min(a, getVal(c)), Infinity);
};

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const decodeSessionCode = (code) => {
  if (code.indexOf('C') == 0) return code.substr(1);
  return code;
};
export const encodeSessionCode = (code) => 'C' + code;
export const exceedsVehicleLimits = (retailer, vehicleTypes) => {
  return vehicleTypes.some(
    (vehicle) =>
      retailer?.weight > vehicle?.maxWeight ??
      retailer?.volume > vehicle?.maxVolume ??
      retailer?.sales > vehicle?.maxValue
  );
};
export const getClientName = () => window?.location?.hostname.split('.')[0].toLowerCase();

export function isInIframe() {
  try {
    return window?.self !== window?.top;
  } catch (e) {
    return true;
  }
}
