import 'chartjs-plugin-datalabels';
import React, { useEffect } from 'react';
import { defaults } from 'react-chartjs-2';
import { ga, useGDispatch, useGState } from 'state/store';
import { useAPI, useFetch } from 'utils/customHooks';
import { getGlobalNodeSelectionOptions, getRootNodes } from 'utils/node';
import { getWorkingDate } from 'utils/utils';

// import 'chartjs-plugin-deferred';

defaults.global.legend.labels.usePointStyle = true;
defaults.global.plugins.datalabels = {
  color: 'rgb(0,0,0,0.87)',
  font: {
    weight: 400,
  },
  display: (ctx) => ctx.dataset.data[ctx.dataIndex],
};

export default function InitialSetup({ query }) {
  const isClient = typeof window === 'object';
  const user = useGState((s) => s.user);

  return isClient &&
    (!['/ssoredirect', '/ssoredirect/'].includes(
      window?.location?.pathname?.toLowerCase().trim()
    ) ||
      user?.id) ? (
    <InitialDataLoad query={query} />
  ) : null;
}

function InitialDataLoad({ query: { branchId, date, nodeId } }) {
  const gDispatch = useGDispatch();
  const [fetch] = useAPI();
  const [fetch2] = useAPI();
  const [fetch3] = useAPI();

  //fetching user details
  const [user] = useFetch('/cookie/user');
  useEffect(() => {
    if (!user) return;
    gDispatch([ga.USER, user]);
  }, [user, gDispatch]);

  const [nodes] = useFetch('/core/panel/node');
  useEffect(() => {
    if (!nodes) return;
    gDispatch([ga.NODES, nodes]);
    // createTree(nodes);
    gDispatch([ga.BRANCHES, getGlobalNodeSelectionOptions(nodes)]);
    if (nodes.length) {
      gDispatch([
        ga.NODE,
        nodes.find((el) => String(el.id) === String(nodeId)) || getRootNodes(nodes)?.[0],
      ]);
      gDispatch([ga.BRANCH, nodes.find((el) => el.id === branchId) || nodes[0]]);
      fetch(`/core/settings?nodeId=${branchId}`, (resp) =>
        gDispatch([ga.CLIENT_PREFERENCE, resp])
      );
      fetch2(`/v3/delivery/panel/failReasons?nodeId=${nodes[0].id}`, (resp) =>
        gDispatch([ga.REASONS, resp])
      );
      fetch3(`/core/panel/permission?nodeId=${nodes[0].id}`, (resp) =>
        gDispatch([ga.PERMISSIONS, resp])
      );
    }
    return () => {
      gDispatch([ga.BRANCHES, []]);
    };
  }, [nodes, gDispatch, fetch, branchId, fetch2, fetch3, nodeId]);

  //Fetching branches list
  // const [branches] = useFetch('/client/branch');
  // useEffect(() => {
  //   if (!branches) return;
  //   const branchesWithAll = branches.length > 1 ? [{ id: -1, name: 'ALL' }, ...branches] : branches;
  //   // gDispatch([ga.BRANCHES, branchesWithAll]);
  //   if (branchesWithAll.length) {
  //     gDispatch([
  //       ga.BRANCH,
  //       branchesWithAll.find((el) => el.id === branchId) || branchesWithAll[0],
  //     ]);
  //     fetch(`/salesman/settings?branchId=${branches[0].id}`, (resp) =>
  //       gDispatch([ga.CLIENT_PREFERENCE, resp])
  //     );
  //     fetch2(`/v3/delivery/panel/failReasons?nodeId=${branches[0].id}`, (resp) =>
  //       gDispatch([ga.REASONS, resp])
  //     );
  //   }
  //   return () => {
  //     gDispatch([ga.BRANCHES, []]);
  //   };
  //   //eslint-disable-next-line
  // }, [branches, gDispatch, fetch]);

  //Fetching failure reasons
  // const [failureReasons] = useFetch();
  // useEffect(() => {
  //   if (!failureReasons) return;
  //   gDispatch([ga.FAILURE_REASONS, failureReasons]);
  // }, [failureReasons, gDispatch]);

  // setting date in url
  useEffect(() => {
    gDispatch([ga.DATE, date ? new Date(date) : getWorkingDate()]);
  }, [date, gDispatch]);
  //setting up plan counts for activity bar
  return <div id="initial-setup"></div>;
}
