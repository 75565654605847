import { useSnackbar as useSnackbarNotistack } from 'notistack';
import numeral from 'numeral';
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { ga, useGDispatch, useGState } from 'state/store';
import fetch from './fetch';
import { get, set } from './persistentStore';

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function useUnobtrusiveLogin() {
  const gDispatch = useGDispatch();
  useEffect(() => {
    gDispatch([ga.UNOBTRUSIVE_LOGIN, true]);
    return function enableNormalLogin() {
      gDispatch([ga.UNOBTRUSIVE_LOGIN, false]);
    };
  }, [gDispatch]);
}
export const byId = (arr, id, key = 'id') => arr?.find(e => e && e[key] === id);

export function useComputePlansDetails(plans) {
  return useMemo(
    () =>
      plans?.plans?.map((p) => {
        const node = byId(plans.nodes, p.branchId);
        const parentNode = byId(plans?.nodes, node?.parentId);
        return { ...p, branchName: node?.name, clientName: parentNode?.name };
      }),
    [plans]
  );
}
export function useLocalStorage(key, value) {
  const [isChanged, setIsChanged] = useState(0);

  const val = useMemo(() => {
    const storageValue = get(key);
    return storageValue ? storageValue : value;
  }, [key, value]);

  useEffect(() => {}, [isChanged, val]);

  const setVal = useCallback(
    (newValue) => {
      set(key, newValue);
      setIsChanged((pre) => pre + 1);
    },
    [key]
  );

  return [val, setVal];
}

export const useRemember = (key, value, ignores, onIgnore) =>
  useMemo(() => {
    const storedValue = get(key);
    if (ignores.includes(value)) {
      if (onIgnore && storedValue) onIgnore(storedValue, value);
      return storedValue;
    }
    if (value !== storedValue) set(key, value);
    return value;
  }, [key, value, ignores, onIgnore]);

async function fetchData(details, initialValue, fullResponse, setData, setLoading, setError) {
  let reqDetails = details;
  let response;
  setLoading(true);
  setError(false);
  try {
    if (details.meta) {
      reqDetails = details.data;
    }
    response = await fetch(reqDetails);
  } catch (error) {
    setError(true);
    setData(initialValue);
  }
  if (details.meta)
    setData({
      meta: details.meta,
      data: fullResponse ? response : response ? response : null,
    });
  else if (response) setData(fullResponse ? response : response);
  else {
    setError(true);
    setData(initialValue);
  }
  setLoading(false);
}

export function useFetchv1(details, change = [], initialValue = [], fullResponse = false) {
  const [data, setData] = useState(initialValue);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    fetchData(details, initialValue, fullResponse, setData, setLoading, setError);
    // eslint-disable-next-line
  }, [...change, refetch]);

  return [
    data,
    loading,
    error,
    () => {
      setRefetch(!refetch);
    },
  ];
}

export function useFetchOnAction(getDetails, change = [], initialValue = [], fullResponse = false) {
  const [data, setData] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [details, setDetails] = useState('FIRST_TIME_USE_IGNORE');

  useEffect(() => {
    if (details === 'FIRST_TIME_USE_IGNORE') return;
    fetchData(getDetails(...details), initialValue, fullResponse, setData, setLoading, setError);
    // eslint-disable-next-line
  }, [details, ...change]);

  const action = useCallback((...data) => setDetails(data), []);

  return [action, data, loading, error];
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

// Hook
export function useKeyPress(targetKey) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  // If pressed key is our target key then set to true
  const downHandler = useCallback(
    ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    },
    [targetKey]
  );

  // If released key is our target key then set to false
  const upHandler = useCallback(
    ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    },
    [targetKey]
  );

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [downHandler, upHandler]); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
}

export function useKeyPressed(targetKey) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(0);

  // If released key is our target key then set to false
  const upHandler = useCallback(
    ({ key }) => {
      if (key === targetKey) {
        setKeyPressed((kp) => kp + 1);
      }
    },
    [targetKey]
  );

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keyup', upHandler);
    };
  }, [upHandler]); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
}

export function useDidUpdateEffect(fn, inputs) {
  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
    // eslint-disable-next-line
  }, inputs);
}

const dataFetchReducer = (state, [action, details]) => {
  switch (action) {
    case 'SET_DETAILS':
      return {
        ...state,
        details,
      };
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSettled: true,
        data: details,
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isSettled: true,
        isLoading: false,
        isError: true,
      };
    case 'REFETCH':
      return {
        ...state,
        details: { ...state.details },
      };
    default:
      throw new Error();
  }
};

export const useFetch = (initialDetails, fullResponse = false) => {
  const [state, dispatch] = useReducer(dataFetchReducer, {
    details: initialDetails,
    isLoading: false,
    isError: false,
    isSettled: false,
    data: null,
  });
  useEffect(() => dispatch(['SET_DETAILS', initialDetails]), [initialDetails]);
  useEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      dispatch(['FETCH_INIT']);
      try {
        const result = await fetch(state.details);
        if (!didCancel) dispatch(['FETCH_SUCCESS', fullResponse ? result : result]);
      } catch (error) {
        if (!didCancel) dispatch(['FETCH_FAILURE']);
      }
    };
    fetchData();
    return () => {
      didCancel = true;
    };
  }, [state.details, fullResponse]);

  const reFetch = useCallback((newDetails) => {
    if (newDetails) dispatch(['SET_DETAILS', newDetails]);
    else dispatch(['REFETCH']);
  }, []);
  return [state.data, state.isLoading, state.isError, reFetch, state.isSettled];
};

export const useAPI = (initialDetails, fullResponse = false) => {
  const responseFns = useRef({
    onSuccess() {},
    onFailure() {},
  });

  const [state, dispatch] = useReducer(dataFetchReducer, {
    details: initialDetails,
    isLoading: false,
    isError: false,
    data: null,
  });

  useDidUpdateEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      dispatch(['FETCH_INIT']);
      try {
        const result = await fetch(state.details);
        if (!didCancel) {
          dispatch(['FETCH_SUCCESS', fullResponse ? result : result]);
          responseFns.current.onSuccess(fullResponse ? result : result);
        }
      } catch (error) {
        if (!didCancel) {
          dispatch(['FETCH_FAILURE']);
          responseFns.current.onFailure(error);
        }
      }
    };
    fetchData();
    return () => {
      didCancel = true;
    };
  }, [state.details, fullResponse]);

  const reFetch = useCallback((newDetails, onSuccess = () => {}, onFailure = () => {}) => {
    if (newDetails) dispatch(['SET_DETAILS', newDetails]);
    else dispatch(['REFETCH']);

    responseFns.current = {
      onSuccess,
      onFailure,
    };
  }, []);

  return [reFetch, state.data, state.isLoading, state.isError];
};

export const useAPIPromise = (fullResponse = false) => {
  const responseFns = useRef({
    onSuccess() {},
    onFailure() {},
  });

  const [state, dispatch] = useReducer(dataFetchReducer, {
    details: null,
    isLoading: false,
    isError: false,
    data: null,
  });

  useDidUpdateEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      dispatch(['FETCH_INIT']);
      try {
        const result = await fetch(state.details);
        if (!didCancel) {
          dispatch(['FETCH_SUCCESS', fullResponse ? result : result.data]);
          responseFns.current.onSuccess(fullResponse ? result : result.data);
        }
      } catch (error) {
        if (!didCancel) {
          dispatch(['FETCH_FAILURE']);
          responseFns.current.onFailure(error);
        }
      }
    };
    fetchData();
    return () => {
      didCancel = true;
    };
  }, [state.details, fullResponse]);

  const reFetch = useCallback((newDetails, onSuccess = () => {}, onFailure = () => {}) => {
    if (newDetails) dispatch(['SET_DETAILS', newDetails]);
    else dispatch(['REFETCH']);

    responseFns.current = {
      onSuccess,
      onFailure,
    };
  }, []);

  return [reFetch, state.data, state.isLoading, state.isError];
};

export const useReducerInit = (data, dispatch) => {
  useEffect(() => {
    if (!data) return;
    dispatch(['INIT', data]);
  }, [data, dispatch]);
};

export const useSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbarNotistack();
  return [enqueueSnackbar, closeSnackbar];
};

export const useWindowSize = () => {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    //eslint-disable-next-line
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};

export function useDerivedState(prop) {
  const [val, setVal] = useState(prop);

  useEffect(() => {
    setVal(prop);
  }, [prop]);

  return [val, setVal];
}

export const useSelectOptions = (arr, deps) =>
  //eslint-disable-next-line
  useMemo(() => arr?.map((el) => ({ value: el, label: el })), deps);

export const useCurrencyFormatter = () => {
  const currencySymbol = useGState((state) => state.clientPreference.currencySymbol);
  const formatCurrency = useCallback(
    (value) => `${currencySymbol || '₹'}${numeral(value).format('0,0')}`,
    [currencySymbol]
  );
  return formatCurrency;
};

export const useBranch = () => useGState((s) => s.branch);
export const useBranchId = () => useGState((s) => s.branch)?.id;
export const useDate = () => useGState((s) => s.date);
export const useUser = () => useGState((s) => s.user);
export const useSettings = () => useGState((s) => s.clientPreference);
